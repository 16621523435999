import { useState, useEffect, lazy, Suspense, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

import {
  changeSidebarType,
  changeSidebarTheme,
  changeLayoutWidth,
  changeTopbarTheme,
} from "../../../actions/layout";
import ErrorBoundary from "../../ErrorBoundary";
import { capitalizedPathName } from "../../../helpers/string";
import Footer from "./Footer";
import useTheme from "../../../helpers/useTheme";
import PreLoader from "../../common/PreLoader";
import {
  isHoaxMerchDomain,
  isLarevivalDomain,
  currentDomain,
  isDevDomain,
  isPrintifyDomain,
  isRakilineDomain,
  isTeeprintcentreDomain,
  isTheSublimationStationDomian,
} from "../../../utils";
import "./styles.scss";
import LoadHelpCrunchChat from "./LoadHelpCrunchChat";
import RightDrawer from "../../RightDrawer";
import { RIGHT_DRAWER_WIDTH } from "../../../constants/dashboard";
import { logoutUser } from "../../../actions/authentication";
// import Avatar from "../../../assets/images/Avatar.png";
// import ItemScannerField from "../../common/ItemScannerField";
import { LOCALSTORAGE_KEYS } from "../../../constants/users";
import { getToken, removeToken } from "../../../helpers/localStorageCache";
import { Alert } from "@mui/material";
import moment from "moment";

const HoaxMerchHeader = lazy(() => import("./HoaxMerch/Header"));
const HoaxMerchSidebar = lazy(() => import("./HoaxMerch/Sidebar"));
const LarevivalHeader = lazy(() => import("./Larevival/Header"));
const LarevivalSidebar = lazy(() => import("./Larevival/Sidebar"));
const RiverrHeader = lazy(() => import("./Riverr/Header"));
const RiverrSidebar = lazy(() => import("./Riverr/Sidebar"));

const VerticalLayout = ({
  children,
  leftSideBarType,
  leftSideBarTheme,
  layoutWidth,
  topbarTheme,
  currentUser,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const mockUser = getToken(LOCALSTORAGE_KEYS.mockUser);
  const isMockUser = Boolean(mockUser?.uid);

  const { enterprise, logo, logoSymbol } = useTheme();

  const [isMobile, setIsMobile] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const currentActiveStep = useSelector(
    (state) => state?.dashboard?.currentActiveStep
  );

  const currentEnterprise = useSelector(
    (state) => state.currentUser.enterprise
  );
  const paymentMethods = useSelector(
    (state) => state.credentials.paymentMethods
  );
  const paymentMethodsLoading = useSelector(
    (state) => state?.credentials?.loading
  );
  const planId = useSelector((state) => state?.pricings?.planId);
  const subscription = useSelector((state) => state.currentUser.subscription);

  const { daysLeft, isTrialAlertActive } = useMemo(() => {
    const trialEndTime = subscription?.trial_end;
    const daysLeft = trialEndTime
      ? moment.unix(trialEndTime).diff(moment(), "days")
      : 0;
    return { daysLeft, isTrialAlertActive: daysLeft > 0 };
  }, [subscription?.trial_end]);

  // redirect admins to profile page if no credit card details added
  useEffect(() => {
    if (
      !isEmpty(currentUser) &&
      currentUser.isEnterpriseAdmin &&
      !currentUser.isProUser &&
      !paymentMethodsLoading &&
      !isDevDomain &&
      !isPrintifyDomain &&
      !isRakilineDomain &&
      !isTeeprintcentreDomain &&
      !isTheSublimationStationDomian
      // (isMerchfillerDomain ||
      //   isMyShirtPodDomain ||
      //   isJmDtgPrintingDomain ||
      //   isDtgShirtsDomain ||
      //   isPrintPoetsDomain ||
      //   isLuxtownDomain)
    ) {
      // if (isEmpty(paymentMethods) && location.pathname !== "/profile") {
      //   history.push("/profile");
      // } else if (
      //   !isEmpty(paymentMethods) &&
      //   !planId &&
      //   location.pathname !== "/pricing"
      // ) {
      //   history.push("/pricing");
      // }

      if (
        location.pathname !== "/pricing" &&
        (isEmpty(paymentMethods) || (!isEmpty(paymentMethods) && !planId))
      ) {
        history.push("/pricing");
      }
    }
  }, [currentUser, paymentMethodsLoading, location]);

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    if (currentUser.isEnterpriseAdmin) {
      document.body.classList.add("is-enterprise-admin");
    } else {
      document.body.classList.remove("is-enterprise-admin");
    }

    if (isTrialAlertActive) {
      document.body.classList.add("isTrialAlertActive");
    } else {
      document.body.classList.remove("isTrialAlertActive");
    }
  }, [currentUser, isTrialAlertActive]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let currentpage = capitalizedPathName(location.pathname);

    document.title = currentpage + ` | ${enterprise?.name}`;
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }

    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }

    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [
    dispatch,
    layoutWidth,
    leftSideBarTheme,
    leftSideBarType,
    topbarTheme,
    location.pathname,
  ]);

  const toggleMenuCallback = () => {
    setIsSidebarOpen(!isSidebarOpen);

    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  const DynamicComponents = (function () {
    if (isHoaxMerchDomain) {
      return {
        HeaderComponent: HoaxMerchHeader,
        SidebarComponent: HoaxMerchSidebar,
      };
    }

    if (isLarevivalDomain) {
      return {
        HeaderComponent: LarevivalHeader,
        SidebarComponent: LarevivalSidebar,
      };
    }

    return {
      HeaderComponent: RiverrHeader,
      SidebarComponent: RiverrSidebar,
    };
  })();

  let isUserFromCurrentDomain = true;

  if (!isEmpty(currentEnterprise?.hostnames) && currentDomain) {
    isUserFromCurrentDomain =
      currentEnterprise?.hostnames?.includes(currentDomain);
  }

  if (!isUserFromCurrentDomain && !isDevDomain) {
    return (
      <div className="p-5">
        <p className="h2">
          Not Authorized to access this domain. Kindly use one of the following
          domains:
        </p>
        <div>
          {currentEnterprise?.hostnames?.map((hn) => (
            <div key={hn} className="h3">
              <a href={`//${hn}`} target="_blank">
                {hn}
              </a>
            </div>
          ))}
        </div>

        <div className="mt-3">
          Click here to{" "}
          <b
            role="button"
            onClick={() => dispatch(logoutUser(history))}
            className="text-primary"
          >
            Logout
          </b>
        </div>
      </div>
    );
  }

  const showFooter = !location.pathname.includes("nester");
  // const LOGO_IMG = enterprise?.logo?.url;
  // const COMPRESSED_LOGO_IMG = enterprise?.compressedLogo?.url || LOGO_IMG;
  const LOGO_IMG = logo?.url;
  const COMPRESSED_LOGO_IMG = logoSymbol?.url;
  // "https://storage.googleapis.com/riverr-enterprise-user.appspot.com/users%2FzNAlxdaZG6hKf6vexv6ljqHyP8i1%2Fmanual-products%2F1666429944058PD061_1_1024x1024.webp";
  return (
    <Suspense fallback={<PreLoader />}>
      <LoadHelpCrunchChat {...{ currentUser }} />

      {isTrialAlertActive && (
        <Alert
          severity="info"
          variant="filled"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: "999",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={<HourglassBottomIcon />}
        >
          <span style={{ fontSize: 20 }}>
            You have <b>{daysLeft} day(s)</b> left in your free trial.
          </span>
        </Alert>
      )}

      <div id="layout-wrapper">
        <DynamicComponents.HeaderComponent
          toggleMenu={toggleMenuCallback}
          currentUser={currentUser}
          type={leftSideBarType}
          marginRight={currentActiveStep ? RIGHT_DRAWER_WIDTH : "initial"}
          marginTop={isTrialAlertActive ? "57px" : 0}
        />
        <DynamicComponents.SidebarComponent
          toggleSidebar={toggleMenuCallback}
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
          logo={LOGO_IMG}
          compressedLogo={COMPRESSED_LOGO_IMG}
          marginTop={isTrialAlertActive ? "57px" : 0}
        />

        <div
          className="main-content"
          style={{
            minHeight: "calc(100vh - 60px)",
            marginRight: currentActiveStep ? RIGHT_DRAWER_WIDTH : "initial",
            marginTop: isMockUser ? "65px" : "initial",
          }}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
          {currentActiveStep && (
            <RightDrawer open={Boolean(currentActiveStep)} />
          )}
        </div>
        {showFooter && <Footer />}
        {/* {currentUser?.isEnterpriseAdmin && <ItemScannerField />} */}
      </div>
    </Suspense>
  );
};

const mapStatetoProps = (state) => {
  return {
    ...state.layout,
    currentUser: state.currentUser?.user || {},
  };
};

export default connect(mapStatetoProps)(VerticalLayout);
